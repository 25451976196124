import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { HeroImage } from 'components/new/hero'
import Content from 'components/new/content'

const CalloutSection = ({
  children,
  contentProps: incomingContentProps,
  overlayOpacity,
  ...props
}) => {
  const contentProps = {
    kind: 'full',
    ...incomingContentProps,
  }
  return (
    <HeroImage {...props}>
      <StyledContent {...contentProps}>{children}</StyledContent>
      <Overlay overlayOpacity={overlayOpacity} />
    </HeroImage>
  )
}

CalloutSection.propTypes = {
  overlayOpacity: PropTypes.number.isRequired,
}

CalloutSection.defaultProps = {
  overlayOpacity: 0.25,
}

const StyledContent = styled(Content)`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`

const Overlay = styled.div`
  background: ${props => `rgba(0, 0, 0, ${props.overlayOpacity})`};
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
`

export default CalloutSection
